import React, { useContext, useState } from 'react';
import Link from 'next/link';
import NavbarWrapper from 'common/components/Navbar';
import { DrawerContext } from 'common/contexts/DrawerContext';
import { MENU_ITEMS } from 'common/data/SaasModern';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';
import Swiftask from 'common/assets/image/logo/swiftask';
import {
  ContainerNavBar,
  LinkNavBar,
  ButtonOpenDrawer,
  LogoNavBar,
} from './style';
import DrawerNavbar from './drawer';
import BtnGetStarted from 'common/components/Button/BTNGetStarted';
import MenuBurgerOpen from 'common/assets/image/logo/icon/menuBurgerOpen';

const Navbar = ({ locale }) => {
  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <>
      {!state.isOpen ? (
        <NavbarWrapper>
          <ContainerNavBar>
            <Link href='/'>
              <Swiftask />
            </Link>
            <LinkNavBar>
              <ScrollSpyMenu
                className='main_menu'
                menuItems={MENU_ITEMS}
                offset={-70}
                locale={locale}
              />
              <Link href='https://app.swiftask.ai' legacyBehavior={true}>
                <BtnGetStarted locale={locale} />
              </Link>
            </LinkNavBar>
            <ButtonOpenDrawer onClick={toggleHandler}>
              <MenuBurgerOpen />
            </ButtonOpenDrawer>
          </ContainerNavBar>
        </NavbarWrapper>
      ) : null}
      <DrawerNavbar
        isOpen={state.isOpen}
        toggleHandler={toggleHandler}
        locale={locale}
      />
    </>
  );
};

export default Navbar;
